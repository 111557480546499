import React, {useContext} from 'react'
import {UserContext} from '../../UserContext'
import Button from '../Button/Button';

interface IProps {}

const ErrorMessage: React.FC<IProps> = () => {
  const [context, setContext] = useContext(UserContext)

  console.log(context)

  if (context.error && !context.errorMessage) {
    return (
      <div className="error-overlay">
        <div className="error-block">
          <div className="error-icon" />
          <div className="error-message">
            <div className="error-title">Ein Fehler ist aufgetreten.</div>
            <div className="error-text">Bitte laden Sie die Seite neu, um es erneut zu versuchen.</div>
            <div className="error-text">
              Bei Problemen können Sie sich an{' '}
              <a href="mailto:support@teleconnect-service.de" target="_blank" rel="noopener noreferrer">
                support@teleconnect-service.de
              </a>{' '}
              wenden.
            </div>
          </div>
          <Button className='error-close' onClick={() => setContext({errorMessage: true})} text={'verstanden'}/>
        </div>
      </div>
    )
  }

  return null
}

export default ErrorMessage
