import React, { useState } from 'react'

export interface StateInterface {
  provider: string
  filter: string
  error: boolean
  errorMessage: boolean
}

type HandleValue = (arg: Partial<StateInterface> | StateInterface) => void

export interface UserContextInterface {
  value: StateInterface
  setValue: HandleValue
}

const initialState = {
  filter: '',
  provider: '',
  error: false,
  errorMessage: false,
}

export const UserContext = React.createContext<[StateInterface, HandleValue]>([initialState, () => ''])

const UserContextProvider = ({ children }: {children: React.ReactNode}): JSX.Element => {
  const [value, setValue] = useState<StateInterface>(initialState)

  const handleValue: HandleValue = (valueHandle: Partial<StateInterface>) => {
    setValue((prevState) => ({ ...prevState, ...valueHandle }))
  }

  return <UserContext.Provider value={[value, handleValue]}>{children}</UserContext.Provider>
}

export default UserContextProvider
