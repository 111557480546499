import React from 'react'

interface IProps {
  text: string
  onClick: () => void
  className?: string
  disabled?: boolean
}


const Button: React.FC<IProps> = ({ text, className = 'primary', disabled = false, onClick }: IProps) => (
  <button onClick={onClick} disabled={disabled} className={['btn', className].join(' ')}>
    {text}
  </button>
)

export default Button
