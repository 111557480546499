import React, {useContext, useEffect, useState} from 'react'
import {Option} from 'react-dropdown'
import {AxiosResponse} from 'axios'
import Widget from '../Widget/Widget'
import {UserContext} from '../../UserContext'
import API from '../../API'
import {W6ChartInit} from './W6ChartService'
import { checkEmptyChart } from '../../utils'

const W6: React.FC = () => {
  const [hasData, setHasData] = useState(true)
  const [loading, setLoading] = useState(true)
  const [checked, setChecked] = useState<boolean>(false)
  const [range, setRange] = useState<number>(0)
  const [trafficOptions, setTrafficOptions] = useState<Array<{value: string; label: string}>>([])
  const [traffic, setTraffic] = useState<string>('')
  const [context, setContext] = useContext(UserContext)

  const title = 'Wie ist der Telefonie Verbrauch meiner Pooling-Verträge?'
  const chartId = 'W6'
  const chartLegends = ['Jahresübersicht', 'Vorjahr']

  const exportLink = `/providers/${context.provider}/w6/export`


  const onChangeCheckbox = ({target}: any) => {
    setChecked(target.checked)
  }

  const onSwitchRange = (index: Option) => {
    setRange(+index.value)
  }

  const onSwitchTraffic = (event: Option) => {
    setTraffic(event.value)
  }

  const getTrafficOptions = async () => {
    const options = await API.get(
      `providers/${context.provider}/vks/voice`,
    ).then((resp: AxiosResponse<{vks: Array<string>}>) =>
      resp.data.vks.map((option) => ({value: option, label: option})),
    )
    setTrafficOptions(options)
    setTraffic(options[0] ? options[0].value : '')
  }

  useEffect(() => {
    if (context.provider) {
      void getTrafficOptions()
    }
  }, [context.provider])

  useEffect(() => {
    if (context.provider) {
      setLoading(true)
      let dispose = () => {''}
      void W6ChartInit(chartId, checked, range, traffic, context, setContext, false).then((chart) => {
        setLoading(false)
        if (chart !== null) {
          checkEmptyChart(chart)
          setHasData(true)
          dispose = () => chart.dispose()
        }
        else {
          setHasData(false)
        }
      })
      return () => dispose()
    }
  }, [checked, range])

  useEffect(() => {
    if (context.provider) {
      setLoading(true)
      let dispose = () => {''}
      void W6ChartInit(chartId, checked, range, traffic, context, setContext, true).then((chart) => {
        setLoading(false)
        if (chart !== null) {
          checkEmptyChart(chart)
          setHasData(true)
          dispose = () => chart.dispose()
        }
        else {
          setHasData(false)
        }
      })
      return () => dispose()
    }
  }, [context, traffic])

  return (
    <>
      {hasData
        ? <Widget
          title={title}
          chartId={chartId}
          onChangeCheckbox={onChangeCheckbox}
          checkedCheckbox={checked}
          onSwitchRange={onSwitchRange}
          activeRange={range}
          showLegend={checked}
          doubleLegend={true}
          onSwitchTraffic={onSwitchTraffic}
          trafficOptions={trafficOptions}
          activeTraffic={traffic}
          chartLegends={range ? [] : chartLegends}
          loading={loading}
          exportLink={exportLink}
        />
        : <></>
      }
    </>
  )
}

export default W6
