import React, { useContext, useEffect, useState } from 'react'
import { Option } from 'react-dropdown'
import Widget from '../Widget/Widget'
import { UserContext } from '../../UserContext'
import { W5ChartInit } from './W5ChartService'
import { checkEmptyChart } from '../../utils'

const W5: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [checked, setChecked] = useState(false)
  const [range, setRange] = useState(0)
  const [context, setContext] = useContext(UserContext)

  const title = 'Nachbuchungen Datenpässe'
  const chartId = 'W5'

  const exportLink = `/providers/${context.provider}/w5/export`

  const onChangeCheckbox = ({ target }: any) => {
    setChecked(target.checked)
  }

  const onSwitchRange = (index: Option) => {
    setRange(+index.value)
  }

  useEffect(() => {
    if (context.provider) {
      setLoading(true)
      let dispose = () => {''}
      void W5ChartInit(chartId, checked, range, context, setContext, false).then((chart) => {
        checkEmptyChart(chart)
        setLoading(false)
        dispose = () => chart.dispose()
      })
      return () => dispose()
    }
  }, [checked, range])

  useEffect(() => {
    if (context.provider) {
      setLoading(true)
      let dispose = () => {''}
      void W5ChartInit(chartId, checked, range, context, setContext, true).then((chart) => {
        checkEmptyChart(chart)
        setLoading(false)
        dispose = () => chart.dispose()
      })
      return () => dispose()
    }
  }, [context])

  return (
    <Widget
      title={title}
      chartId={chartId}
      onChangeCheckbox={onChangeCheckbox}
      checkedCheckbox={checked}
      onSwitchRange={onSwitchRange}
      activeRange={range}
      showLegend={checked && range === 2}
      loading={loading}
      exportLink={exportLink}
    />
  )
}

export default W5
