import { Sprite } from "@amcharts/amcharts4/core"

export function formatISODate(isoDate: string): string {
  try {
    const parsedISODate = new Date(isoDate).toISOString()
    const [date, time] = parsedISODate.split('T')
    const [year, month, day] = date.split('-')
    const [hour, minute, _] = time.split(':')

    return `${day}.${month}.${year} ${hour}:${minute}`
  } catch (error) {
    if (error instanceof RangeError) {
      return ''
    } else {
      throw error
    }
  }
}

// check if data of Chart is empty, if so display "no data" message
export function checkEmptyChart(chart: Sprite) {
  if (!('data' in chart) ||
    ('data' in chart 
      && chart['data'] instanceof Object 
      && Object.keys(chart['data']).length === 0)) {
    const modal = chart.openModal('keine Daten vorhanden')
    if (modal) {
      modal.closable = false
      if (modal.elements.content) modal.elements.content.className += ' emptyChart'
      if (modal.elements.curtain) modal.elements.curtain.className += ' emptyChart'
    }
  }
}
