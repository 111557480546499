import React, {useContext, useEffect, useState} from 'react'
import Widget from '../Widget/Widget'
import {UserContext} from '../../UserContext'
import {W3ChartInit} from './W3ChartService'
import { checkEmptyChart } from '../../utils'

const W3: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [checked, setChecked] = useState(false)
  const [footerTitle, setFooterTitle] = useState('')
  const [context, setContext] = useContext(UserContext)

  const title = 'Übersicht der aktiven Verträge'
  const chartId = 'W3'

  const onChangeCheckbox = ({target}: any) => {
    setChecked(target.checked)
  }

  useEffect(() => {
    setLoading(true)
    let dispose = () => {''}
    void W3ChartInit(chartId, checked, context, setContext, setFooterTitle).then((chart) => {
      checkEmptyChart(chart)
      setLoading(false)
      dispose = () => chart.dispose()
    })
    return () => dispose()
  }, [])

  return (
    <Widget
      title={title}
      chartHeight={'293px'}
      chartId={chartId}
      footerTitle={footerTitle}
      onChangeCheckbox={onChangeCheckbox}
      checkedCheckbox={checked}
      loading={loading}
      notFiltering={true}
    />
  )
}

export default W3
