import React, { useContext, useEffect, useState } from 'react'
import { Option } from 'react-dropdown'
import Widget from '../Widget/Widget'
import { UserContext } from '../../UserContext'
import { W1ChartInit } from './W1ChartService'
import { checkEmptyChart } from '../../utils'

const W1: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [checked, setChecked] = useState(false)
  const [tab, setTab] = useState(0)
  const [range, setRange] = useState(0)

  const title = 'Welche Kosten haben meine Verträge verursacht?'
  const chartId = 'W1'
  const switchersTitle = ['Gesamtkosten', 'Variable Kosten', 'Sprache']
  const [context, setContext] = useContext(UserContext)

  const onChangeCheckbox = ({ target }: any) => {
    setChecked(target.checked)
  }

  const onSwitchTab = (index: number) => {
    setTab(index)
  }

  const onSwitchRange = (index: Option) => {
    setRange(+index.value)
  }

  useEffect(() => {
    if (context.provider) {
      setLoading(true)
      let dispose = () => {''}
      void W1ChartInit(chartId, tab, checked, range, context, setContext, false).then((chart) => {
        checkEmptyChart(chart)
        setLoading(false)
        dispose = () => chart.dispose()
      })
      return () => dispose()
    }
  }, [tab, checked, range])

  useEffect(() => {
    if (context.provider) {
      setLoading(true)
      let dispose = () => {''}
      void W1ChartInit(chartId, tab, checked, range, context, setContext, true).then((chart) => {
        checkEmptyChart(chart)
        setLoading(false)
        dispose = () => chart.dispose()
      })
      return () => dispose()
    }
  }, [context])

  return (
    <Widget
      title={title}
      chartId={chartId}
      onChangeCheckbox={onChangeCheckbox}
      checkedCheckbox={checked}
      switchersTitle={switchersTitle}
      onSwitchTab={onSwitchTab}
      onSwitchRange={onSwitchRange}
      activeTab={tab}
      activeRange={range}
      chartHeight={'306px'}
      loading={loading}
    />
  )
}

export default W1
